
import InfiniteScroll from 'infinite-scroll';

function LoadMore() {
    const loadMoreButtonClass = '.load-more';
    const loadMoreContainerClass = '.js-load-more-container';

    var elem = document.querySelector(loadMoreContainerClass);

    if (!elem) {
        return;
    }

    // Delete old InfiniteScroll if exists
    const oldInfScroll = InfiniteScroll.data(elem);

    if (oldInfScroll) {
        oldInfScroll.destroy();
    }

    var infScroll = new InfiniteScroll( elem, {
      // defaults listed

      // REQUIRED. Determines the URL for the next page
      // Set to selector string to use the href of the next page's link
      // path: '.pagination__next'
      // Or set with {{#}} in place of the page number in the url
      // path: '/blog/page/{{#}}'
      // or set with function
      // path: function() {
      //   return return '/articles/P' + ( ( this.loadCount + 1 ) * 10 );
      // }
      path: function() {
          function getURLParameter(name) {
              return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
          }

          let currentPage = getURLParameter('page');
          if (currentPage && parseInt(currentPage) != this.loadCount) {
              this.loadCount = parseInt(currentPage);
          }

          if(!currentPage) {
              this.loadCount = 1;
          }

          const url = $('.js-search-url').attr('href');
          let questionMark = '?';
          if (url.indexOf('?') !== -1) {
              questionMark = '';
          }
          var pageNumber = this.loadCount + 1;
          return `${url}${questionMark}&page=${pageNumber}`;
      },

      append: '.js-load-more-item',
      // REQUIRED for appending content
      // Appends selected elements from loaded page to the container

      checkLastPage: true,
      // Checks if page has path selector element
      // Set to string if path is not set as selector string:
      //   checkLastPage: '.pagination__next'

      prefill: false,
      // Loads and appends pages on intialization until scroll requirement is met.

      responseType: 'document',
      // Sets the type of response returned by the page request.
      // Set to 'text' to return flat text for loading JSON.

      outlayer: false,
      // Integrates Masonry, Isotope or Packery
      // Appended items will be added to the layout

      scrollThreshold: 400,
      // Sets the distance between the viewport to scroll area
      // for scrollThreshold event to be triggered.

      elementScroll: false,
      // Sets scroller to an element for overflow element scrolling

      loadOnScroll: false,
      // Loads next page when scroll crosses over scrollThreshold

      history: 'replace',
      // Changes the browser history and URL.
      // Set to 'push' to use history.pushState()
      //    to create new history entries for each page change.

      historyTitle: false,
      // Updates the window title. Requires history enabled.

      hideNav: undefined,
      // Hides navigation element

      status: undefined,
      // Displays status elements indicating state of page loading:
      // .infinite-scroll-request, .infinite-scroll-load, .infinite-scroll-error
      // status: '.page-load-status'

      button: loadMoreButtonClass,
      // Enables a button to load pages on click
      // button: '.load-next-button'

      onInit: function() {
          this.loadCount = 1;
      },
      // called on initialization
      // useful for binding events on init
      // onInit: function() {
      //   this.on( 'append', function() {...})
      // }

      debug: false,
      // Logs events and state changes to the console.
    })

}

export default LoadMore;
