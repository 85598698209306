
import DetectIE from './detect-ie';

class FilterSearch {

    constructor( node ) {

        // Bind node to class
        this.$node = $( node );

        //
        this.$searchInput = this.$node.find( '.inline-search__input' );
        this.$searchButton = this.$node.find( '.inline-search__icon' );
        this.$form = $(this.$node.parents('form:first'));
        this.bindEvents();
    }

    onSearchClick( event ){
        event.preventDefault();

        // Capture input value
        let searchValue = this.$searchInput.val();

        // Fire an event on window with a custom filter object for use elsewhere
        $( window ).trigger( 'results:filter', {
            filterType : 'search',
            filterOn : searchValue,
            form: this.$form,
            useAjax: this.useAjax(),
        });
    }


    bindEvents() {
        // Bind the search button to search although it is slightly redundant
        this.$searchButton.on( 'click', ( e ) => this.onSearchClick( e ) );
        this.$form.submit(e => this.onSearchClick(e));
        // Bind the searchinput on keyup so we search everytime a user enters a new value
        // We do not want AJAX requests to be searched upon a key press. We also don't want the filter
        // to run with every key press in IE because it has performance issues - see ticket 225
        if (!this.useAjax() && !DetectIE()) {
            this.$searchInput.on( 'keyup', ( e ) => this.onSearchClick( e ) );
        }
    }

    useAjax() {
        if (!this.$form) {
            return false;
        }

        return Boolean(this.$form.data('useAjax'));
    }

}

export default FilterSearch;
