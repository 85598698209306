// Adapted from https://github.com/nhsuk/nhsuk-frontend/blob/main/packages/components/header/header.js
// Takes the site header nav bar and makes it dynamic for mobile

class MobileMenu {
  constructor() {
    this.menuIsOpen = false;
    this.navigation = document.querySelector(".menu-main");
    this.navigationList = document.querySelector(".menu-main__container");
    this.mobileMenu = document.createElement("ul");
    this.dropdownWrapper = document.createElement("div");
    this.dropdownWrapper.appendChild(this.mobileMenu);
    this.dropdownWrapper.classList.add(
      "header__drop-down",
      "header__drop-down--hidden"
    );

    this.mobileMenuToggleButton = document.querySelector(
      ".header__menu-toggle"
    );
    this.mobileMenuCloseButton = document.createElement("button");
    this.mobileMenuContainer = document.querySelector(".mobile-menu-container");
    this.fullScreenSecondaryMenu = document.querySelector(".menu-secondary");

    // secondary menu for links at the top on mobile
    const secondaryMenuToggleContainer = document.createElement("li");
    secondaryMenuToggleContainer.classList.add(
      "menu-main__item",
      "mobile-secondary-menu__more-button-container"
    );
    this.mobileMenu.appendChild(secondaryMenuToggleContainer);
    const secondaryMenuToggle = document.createElement("button");

    // chevron for secondary menu
    const chevronSvgNameSpace = "http://www.w3.org/2000/svg";
    const chevron = document.createElementNS(chevronSvgNameSpace, "svg");
    chevron.setAttribute("class", "icon__chevron-right");
    chevron.setAttribute("xmlns", chevronSvgNameSpace);
    chevron.setAttribute("viewBox", "0 0 24 24");
    chevron.setAttribute("aria-hidden", "true");
    chevron.setAttribute("height", "34");
    chevron.setAttribute("width", "34");
    const chevronPath = document.createElementNS(chevronSvgNameSpace, "path");
    chevronPath.setAttribute(
      "d",
      "M15.5 12a1 1 0 0 1-.29.71l-5 5a1 1 0 0 1-1.42-1.42l4.3-4.29-4.3-4.29a1 1 0 0 1 1.42-1.42l5 5a1 1 0 0 1 .29.71z"
    );
    chevron.appendChild(chevronPath);

    secondaryMenuToggle.onclick = this.toggleSecondaryMenu.bind(this);
    secondaryMenuToggle.classList.add(
      "menu-main__link",
      "mobile-secondary-menu__more-button"
    );
    secondaryMenuToggle.innerText = "More";
    secondaryMenuToggleContainer.appendChild(secondaryMenuToggle);
    secondaryMenuToggle.appendChild(chevron);
    this.secondaryMenu = document.querySelector(".mobile-secondary-menu");
    const secondaryMenuCloseButton = document.querySelector(
      ".mobile-secondary-menu__back-button"
    );
    secondaryMenuCloseButton.onclick = this.toggleSecondaryMenu.bind(this);

    // Menu close button inside secondary menu
    const closeMenuItem = document.createElement("li");
    closeMenuItem.classList.add("menu-main__item");

    const closeMenuButton = document.createElement("button");
    closeMenuButton.classList.add("nhsuk-header__navigation-close");
    closeMenuButton.id = "close-menu";

    closeMenuItem.appendChild(closeMenuButton);

    // SVG for the close icon
    const menuSvgNameSpace = "http://www.w3.org/2000/svg";
    const closeIcon = document.createElementNS(menuSvgNameSpace, "svg");
    closeIcon.setAttribute("class", "nhsuk-icon nhsuk-icon__close");
    closeIcon.setAttribute("xmlns", menuSvgNameSpace);
    closeIcon.setAttribute("viewBox", "0 0 24 24");
    closeIcon.setAttribute("aria-hidden", "true");
    closeIcon.setAttribute("focusable", "false");
    closeIcon.setAttribute("height", "27");
    closeIcon.setAttribute("width", "27");

    const menuPath = document.createElementNS(menuSvgNameSpace, "path");
    menuPath.setAttribute(
      "d",
      "M13.41 12l5.3-5.29a1 1 0 1 0-1.42-1.42L12 10.59l-5.29-5.3a1 1 0 0 0-1.42 1.42l5.3 5.29-5.3 5.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l5.29-5.3 5.29 5.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"
    );

    closeIcon.appendChild(menuPath);
    closeMenuButton.appendChild(closeIcon);

    // Hidden span for accessibility
    const visuallyHiddenSpan = document.createElement("span");
    visuallyHiddenSpan.classList.add("nhsuk-u-visually-hidden");
    visuallyHiddenSpan.innerText = "Close menu";

    closeMenuButton.appendChild(visuallyHiddenSpan);
    closeMenuButton.onclick = this.closeMobileMenu.bind(this);

    this.dropdownWrapper.prepend(closeMenuButton);

    // for the sub menu that appears on hover of one of the nav items on full screen
    this.dropdownMenuLink = document.querySelectorAll(".dropdown-menu__link");
    this.dropdownSubMenuLink = document.querySelectorAll(
      ".dropdown-menu__sub-menu-link"
    );
    this.teaserSubMenuLink = document.querySelectorAll(
      ".dropdown-menu__teasers .teaser__container"
    );

    // for tabbing purposes
    this.isTabbing = false;
    document.addEventListener("keydown", (e) => {
      if (e.key === "Tab") {
        this.isTabbing = true;
      }
    });

    this.breakpoints = [];
    this.width = document.body.offsetWidth;
    this.init();
  }

  init() {
    if (
      !this.navigation ||
      !this.navigationList ||
      !this.mobileMenuToggleButton ||
      !this.mobileMenuContainer
    ) {
      return;
    }

    this.setupMobileMenu();
    this.calculateBreakpoints();
    this.updateNavigation();
    this.doOnOrientationChange();
    this.updateTabIndex();

    this.handleResize = this.debounce(() => {
      this.calculateBreakpoints();
      this.updateNavigation();
      this.updateTabIndex();
    });

    this.mobileMenuToggleButton.addEventListener(
      "click",
      this.toggleMobileMenu.bind(this)
    );
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("orientationchange", this.doOnOrientationChange());
  }

  debounce(func, timeout = 100) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  /**
   * Calculate breakpoints.
   *
   * Calculate the breakpoints by summing the widths of
   * each navigation item.
   *
   */
  calculateBreakpoints() {
      let childrenWidth = 0;
      for (let i = 0; i < this.navigationList.children.length; i++) {
          childrenWidth += this.navigationList.children[i].offsetWidth;
          
      // force the more button to appear at >= 800 pixels, otherwise 153 is the smallest buffer to keep as many options on the screen while the more button is there
      if (i === this.navigationList.children.length - 1) {
        this.breakpoints[i] =
          childrenWidth + 153 >= 800 ? 800 : childrenWidth + 153;
      } else {
        this.breakpoints[i] = childrenWidth;
      }
    }
  }

  // Add the mobile menu to the DOM
  setupMobileMenu() {
    this.mobileMenuContainer.appendChild(this.dropdownWrapper);

    // Construct the home button
    const homeMenuItem = document.createElement("li");
    homeMenuItem.classList.add("menu-main__item");
    const homeLink = document.createElement("a");
    homeLink.classList.add("menu-main__link");
    homeLink.href = "/";
    homeLink.innerText = "Home";

    // Append it
    homeMenuItem.appendChild(homeLink);

    //Construct the Menu header
    const menuHeader = document.createElement("li");
    menuHeader.classList.add("menu-main__item", "menu-main__item--header");

    // Append the Home button to the mobile menu first
    this.dropdownWrapper.prepend(homeMenuItem);
  }

  /**
   * Close the mobile menu
   *
   * Closes the mobile menu and updates accessibility state.
   *
   * Removes the margin-bottom from the navigation
   */
  closeMobileMenu() {
    const chevron = document.querySelector(".icon__chevron-down");
    this.menuIsOpen = false;
    this.dropdownWrapper.classList.add("header__drop-down--hidden");
    this.navigation.style.marginBottom = 0;
    this.mobileMenuToggleButton.setAttribute("aria-expanded", "false");
    this.mobileMenuToggleButton.focus();
    chevron.style.transform = "rotate(90deg)";
    this.mobileMenuCloseButton.removeEventListener(
      "click",
      this.closeMobileMenu.bind(this)
    );
    document.removeEventListener("keydown", this.handleEscapeKey.bind(this));
  }

  /**
   * Escape key handler
   *
   * This function is called when the user
   * presses the escape key to close the mobile menu.
   *
   */
  handleEscapeKey(e) {
    if (e.key === "Escape") {
      this.closeMobileMenu();
    }
  }

  /**
   * Open the mobile menu
   *
   * Opens the mobile menu and updates accessibility state.
   *
   * The mobile menu is absolutely positioned, so it adds a margin
   * to the bottom of the navigation to prevent it from overlapping
   *
   * Adds event listeners for the close button,
   */

  openMobileMenu() {
    const chevron = document.querySelector(".icon__chevron-down");
    this.menuIsOpen = true;
    this.dropdownWrapper.classList.remove("header__drop-down--hidden");
    const marginBody = this.mobileMenu.offsetHeight;
    this.mobileMenuToggleButton.setAttribute("aria-expanded", "true");
    chevron.style.transform = "rotate(270deg)";

    // add event listener for esc key to close menu
    document.addEventListener("keydown", this.handleEscapeKey.bind(this));

    // add event listener for close icon to close menu
    this.mobileMenuCloseButton.addEventListener(
      "click",
      this.closeMobileMenu.bind(this)
    );
  }

  /**
   * Handle menu button click
   *
   * Toggles the mobile menu between open and closed
   */
  toggleMobileMenu() {
    if (this.menuIsOpen) {
      this.closeMobileMenu();
      this.secondaryMenu.classList.remove("mobile-secondary-menu--visible");
      this.dropdownWrapper.classList.add("header__drop-down--hidden");
    } else {
      this.openMobileMenu();
    }
  }

  toggleSecondaryMenu() {
    const isVisible = this.secondaryMenu.classList.toggle(
      "mobile-secondary-menu--visible"
    );
    this.dropdownWrapper.classList.toggle("header__drop-down--hidden");

    if (isVisible && this.isTabbing) {
      const backButton = document.querySelector(
        ".mobile-secondary-menu-chevron"
      );
      if (backButton) {
        backButton.focus();
      }
    } else if (this.isTabbing) {
      const moreButton = document.querySelector(
        ".mobile-secondary-menu__more-button"
      );
      if (moreButton) {
        moreButton.focus();
      }
    }

    window.addEventListener("resize", () => {
      this.secondaryMenu.classList.remove("mobile-secondary-menu--visible");
      this.dropdownWrapper.classList.add("header__drop-down--hidden");
    });
  }

  /**
   * Update nav for the available space
   *
   * If the available space is less than the current breakpoint,
   * add the mobile menu toggle button and move the last
   * item in the list to the drop-down list.
   *
   * If the available space is greater than the current breakpoint,
   * remove the mobile menu toggle button and move the first item in the
   *
   * Additionally will close the mobile menu if the window gets resized
   * and the menu is open.
   */

  updateNavigation() {
    const availableSpace = this.navigation.offsetWidth;
    let itemsVisible = this.navigationList.children.length;

    if (availableSpace < this.breakpoints[itemsVisible - 1]) {
      this.navigationList.style.justifyContent = "";
      this.mobileMenuToggleButton.classList.add("header__menu-toggle--visible");
      this.mobileMenuContainer.classList.add("mobile-menu-container--visible");
      if (itemsVisible === 2) {
        return;
      }
      while (availableSpace < this.breakpoints[itemsVisible - 1]) {
        this.mobileMenu.insertBefore(
          this.navigationList.children[itemsVisible - 2],
          this.mobileMenu.firstChild
        );
        itemsVisible -= 1;
      }
    } else if (availableSpace >= this.breakpoints[itemsVisible]) {
      while (availableSpace >= this.breakpoints[itemsVisible]) {
        this.navigationList.insertBefore(
          this.mobileMenu.removeChild(this.mobileMenu.firstChild),
          this.mobileMenuContainer
        );
        itemsVisible += 1;
      }
    }

    if (this.mobileMenu.children.length === 1) {
      this.navigationList.style.justifyContent = "space-between";
      this.mobileMenuToggleButton.classList.remove(
        "header__menu-toggle--visible"
      );
      this.mobileMenuContainer.classList.remove(
        "mobile-menu-container--visible"
      );
      this.mobileMenuContainer.style.display = "none";
      this.fullScreenSecondaryMenu.style.display = "flex";
    } else {
      this.navigationList.style.justifyContent = undefined;
      this.fullScreenSecondaryMenu.style.display = "none";
      this.mobileMenuContainer.style.display = "block";
    }

    if (document.body.offsetWidth !== this.width && this.menuIsOpen) {
      this.closeMobileMenu();
    }
  }

  doOnOrientationChange() {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    if (isLandscape) {
      setTimeout(() => {
        this.calculateBreakpoints();
        this.updateNavigation();
      }, 200);
    }
  }

  // to stop sub menu items from being tabbable on mobile view
  updateTabIndex() {
    const setTabIndex = (links, value) => {
      links.forEach((link) => {
        link.setAttribute("tabindex", value);
        link.setAttribute("aria-hidden", value === "-1" ? "true" : "false");
      });
    };

    if (this.mobileMenu.children.length > 1) {
      setTabIndex(this.dropdownMenuLink, "-1");
      setTabIndex(this.dropdownSubMenuLink, "-1");
      setTabIndex(this.teaserSubMenuLink, "-1");
    } else {
      setTabIndex(this.dropdownMenuLink, null);
      setTabIndex(this.dropdownSubMenuLink, null);
      setTabIndex(this.teaserSubMenuLink, null);
    }
  }
}

export default MobileMenu;
