

class Browse {

    constructor( ) {
        this.$browseMain =                  $( '.browse' );
        this.$browseContainer =             $( '.browse__container' );
        this.$browseColumn =                $( '.browse__column' );
        this.$browseItem =                  $( '.browse__item' );
        this.$browseItemContainer =         $( '.browse__item-container' );
        this.$browseIndex =                 $( '.js-index' );
        this.$breadcrumbs =          $( '.js-breadcrumbs' );

        this.bindEvents();
    }

    getBranch(arr, node_id) {
        // Given a tree and a node id, returns an ordered inclusive list of ancestors
        const browse = this;

        if (arr.length === 0) {
            return [];
        }

        var matched_nodes = arr.filter(node => node['id'] === node_id);

        // ID doesn't match any page at this level
        // It might be a child so keep going
        if (matched_nodes.length === 0) {
            var all_matched = [];
            arr.filter(node => node.hasOwnProperty('children')).forEach(function(parental_node) {
                var matched_children = browse.getBranch(parental_node['children'], node_id);
                if (matched_children.length > 0) {
                    all_matched = [parental_node].concat(matched_children);
                }
            });
            return all_matched;
        }
        // ID matches a page at this level
        else if (matched_nodes.length === 1) {
            var matched_node = matched_nodes[0];
            return [matched_node];
        }
        else if (matched_nodes.length > 1) {
            console.error('Duplicate page IDs.');
        }
        return [];
    }

    nextLevel(event) {
        event.preventDefault();

        const $clicked        = $(event.currentTarget);
        const id              = $clicked.data('pageId');
        const branch = this.getBranch(window.tree, parseInt(id));

        this.setBrowseLevelClass(branch.length);
        this.setUrl(branch[branch.length - 1]);
        this.setBreadcrumb(branch);

        const $childColumn     = $(`[data-parent-id='${id}']`);

        this.updateActive([this.$browseItem, this.$browseColumn], [$clicked.parent(), $childColumn]);

        // Make ancestors active
        for (var i = 0; i < branch.length - 1; i++) {
            $(`[data-parent-id='${branch[i]['id']}']`).addClass('active');
            this.$browseItem.find(`[data-page-id='${branch[i]['id']}']`).parent().addClass('active');
        }
    }

    updateActive(remove, add) {
        for (var i = 0; i < remove.length; i++) {
            remove[i].removeClass('active');
        }
        for (var i = 0; i < add.length; i++) {
            add[i].addClass('active');
        }
    }

    setUrl(page) {
        history.pushState({}, page['title'], page['url']);
        document.title = page['title'];
        $('.page-header__heading').text(page['title']);
    }

    setBreadcrumb(links) {
        var breadcrumbs = '';
        for (var i = 0; i < window.tree_ancestors.length; i++) {
            if (i < window.tree_ancestors.length - 1 || links.length > 1) {
                breadcrumbs = breadcrumbs + '<li class="breadcrumb__item"><a class="breadcrumb__link" href="' + window.tree_ancestors[i]['url'] +'">'+ window.tree_ancestors[i]['title'] +'</a><span class="breadcrumb__divide">/</span></li>';
            }
            else {
                breadcrumbs = breadcrumbs + '<li class="breadcrumb__item"><a class="breadcrumb__link" href="' + window.tree_ancestors[i]['url'] +'">' + window.tree_ancestors[i]['title'] +'</a></li>';
            }
        }
        for (var i = 0; i < links.length - 1; i++) {
            // Don't add a slash to the last breadcrumb
            if (i < links.length - 2) {
                breadcrumbs = breadcrumbs + '<li class="breadcrumb__item"><a class="breadcrumb__link" href="' + links[i]['url'] +'">'+ links[i]['title'] + '</a><span class="breadcrumb__divide">/</span></li>';
            }
            else {
                breadcrumbs = breadcrumbs + '<li class="breadcrumb__item"><a class="breadcrumb__link" href="' + links[i]['url'] +'">'+ links[i]['title'] + '</a></li>';
            }
        }
        this.$breadcrumbs.html(breadcrumbs);
    }

    setBrowseLevelClass(level) {
        this.$browseMain.removeClass('level-one level-two');
        if (level == 2) {
            this.$browseMain.addClass('level-two');
        } else {
            this.$browseMain.addClass('level-one');
        }
    }

    bindEvents() {
        this.$browseIndex.on('click', (event) => this.nextLevel(event) );
    }

}

export default Browse;
