
import Results from './results';
import Filter from './filter';
import FilterSearch from './filter-search';

// Initialise the classes on our desired nodes!
function ListingFilters() {

    const filtersContainer = '.js-filters__item';
    const resultsContainer = '.listing';
    const searchContainer = '.inline-search';

    // Create an insteance of the listing
    $( resultsContainer ).each(function(){
        new Results( this );
    });

    // Create an instance of filters for each select box we find with the specific class;
    $( filtersContainer ).each(function(){
        new Filter( this );
    });

    // Create an instance of filters for each select box we find with the specific class;
    $( searchContainer ).each(function(){
        new FilterSearch( this );
    });

}

export default ListingFilters;
