class Browse {
  constructor() {
    $(document).ready(function () {
      // When an 'a' tag within a '.charity-menu-main__dropdown' gains focus
      $(".charity-menu-main__dropdown a").on("focus", function () {
        // Find the closest '.charity-menu-main__dropdown' and add the 'active' class
        $(this).closest(".charity-menu-main__item").addClass("active-menu");
      });
      // When an 'a' tag within a '.charity-menu-main__dropdown' loses focus
      $(".charity-menu-main__dropdown a").on("blur", function () {
        // Find the closest '.charity-menu-main__dropdown' and remove the 'active' class
        $(this).closest(".charity-menu-main__item").removeClass("active-menu");
      });

      $(".charity-menu-main__link").on("click", function () {
        // Find the closest '.charity-menu-main__dropdown' and add the 'active' class
        $(this).closest(".charity-menu-main__item").toggleClass("active-menu");
        $(".charity-menu-main__item")
          .not($(this).closest(".charity-menu-main__item"))
          .removeClass("active-menu");
      });
    });
  }
}

export default Browse;
