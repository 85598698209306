import 'owl.carousel';

function Carousel() {
  // Attach Carousel to dom node, with options
  $('.js-carousel').owlCarousel({
    dots: false,
    center: false,
    autowidth: true,
    loop: true,
    nav: true,
    margin: 28,

    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
        slideBy: 1,
      },
      // breakpoint from 650 up
      500: {
        items: 2,
        slideBy: 2,
      },
      // breakpoint from 650 up
      650: {
        items: 3,
        slideBy: 3,
      },
      // breakpoint from 800 up
      900: {
        items: 4,
        slideBy: 4,
      },
      // breakpoint from 1100 up
      1100: {
        items: 5,
        slideBy: 5,
      },
      // breakpoint from 1100 up
      1250: {
        items: 6,
        slideBy: 6,
      },
    },
  });

  // Attach Carousel to dom node, with options
  $('.js-carousel--mini').owlCarousel({
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
        nav: true,
        dots: false,
      },
      // breakpoint from 650 up
      650: {
        items: 2,
        nav: true,
        margin: 28,
        dots: false,
      },
      // breakpoint from 800 up
      800: {
        items: 2,
        nav: true,
        dots: false,
      },
      // breakpoint from 1100 up
      1100: {
        items: 3,
        nav: true,
        loop: false,
        dots: false,
      },
    },
  });

  $('.js-slideshow').owlCarousel({
    items: 1,
    singleitem: true,
    autoHeight: true,
    nav: true,
    dots: true,
  });

  $('.js-slideshow--no-nav').owlCarousel({
    items: 1,
    singleitem: true,
    nav: false,
    dots: true,
  });

  // Add aria-label to owl dots for accessibility
  $('.owl-carousel').each(function () {
    $(this)
      .find('.owl-dot')
      .each(function (index) {
        $(this).attr('aria-label', 'Slide ' + (index + 1));
      });
  });
}

export default Carousel;
