
import { toggleActiveClass } from './toggle';

class SearchReveal {

    constructor( ) {
        this.$searchNode = $('.menu-main__search');
        this.$searchItem = $('.js-search-toggle');

        this.bindEvents();
    }

    toggleSearch() {
        toggleActiveClass( this.$searchNode );
    }

    bindEvents() {
        this.$searchItem.on('click', () => this.toggleSearch() );
    }

}

export default SearchReveal;
