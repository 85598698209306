

// Generic function to toggle active class
function toggleActiveClass(element) {
    const activeClass = 'active';
    $(element).toggleClass(activeClass);
}

// Generic function to remove active class
function removeActiveClass(element) {
    const activeClass = 'active';
    $(element).removeClass(activeClass);
}

// Generic function to toggle active class
function toggleActiveClassParent(element) {
    const activeClass = 'active';
    $(element).parent().toggleClass(activeClass);
}

// Generic function to fixed class
function toggleFixedClass(element) {
    const fixedClass = 'fixed';
    $(element).toggleClass(fixedClass);
}

export { toggleActiveClass, removeActiveClass, toggleActiveClassParent, toggleFixedClass };
