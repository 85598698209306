

class Map {

    constructor( node ){

        this.$map = $( node );
        this.location = {
            lat : this.$map.data( 'latitude' ),
            lng : this.$map.data( 'longitude' )
        }
        this.zoom = this.$map.data( 'zoom-level' );

        // populated in mapLoad();
        this.googleMap = null;
        this.marker = null;

        // Load the map!
        this.mapLoad();

    }

    mapLoad(){
        this.googleMap = new google.maps.Map(this.$map[0], {
          zoom: this.zoom,
          scrollwheel: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: true,
          center: this.location
        });
        this.marker = new google.maps.Marker({
          position: this.location,
          map: this.googleMap
        });
    }

}


// Bind function to window for google maps callback
window.initMap = function initMap(){

    let $maps = $('.js-map');
    $maps.each(function(){
        new Map( this );
    })

}

export default Map;
