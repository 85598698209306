
import { toggleActiveClassParent } from './toggle';

class ToggleMobileFilters {

    constructor( ) {
        this.$menuNode = $('.js-show-menu');
        this.bindEvents();
    }

    toggleMobileMenu( e ) {
        // prevent default link action
        e.preventDefault();
        var element = e.currentTarget;
        toggleActiveClassParent(element);
    }

    bindEvents() {
        this.$menuNode.on('click', (e) => this.toggleMobileMenu(e) );
    }

}

export default ToggleMobileFilters;
