function searchSuggestions() {
    let latestSearchId = 0;

    const $resultsContainer = $(".site-header__search-suggestions"),
        $resultsList = $(".site-header__search-container ul"),
        $searchInput = $('.site-header__search-container input[type="search"]'),
        $commonAncestorIdInput = $(
            '.site-header__search-container input[name="common_ancestor_id"]'
        ),
        resultsContainerShow = "site-header__search-suggestions--show";

    let mapping = {
        url: "url",
        title: "title",
    };

    // Populate results container
    function populateResults(results) {
        // Map result items
        let listItems = results.results
            .map(function (item) {
                const listHtml =
                    '<li class="site-header__search-suggestion"><a class="site-header__search-suggestion-link" href="' +
                    item[mapping.url] +
                    '">' +
                    item[mapping.title] +
                    "</a>";
                if (item.site)
                    return (
                        listHtml +
                        '<span class="site-header__search-suggestion-sitename">(' +
                        item.site +
                        ")</span></li>"
                    );
                return listHtml + "</li>";
            })
            .join("");

        $resultsList.html(listItems);
        showResults();
    }

    // Empty results container
    function emptyResults() {
        hideResults();
    }

    function showResults() {
        $resultsContainer.addClass(resultsContainerShow);
    }

    function hideResults() {
        $resultsContainer.removeClass(resultsContainerShow);
    }

    // Perform search and populate/empty results container
    function search(query) {
        const currentSearchId = ++latestSearchId;
        const commonAncestorID = $commonAncestorIdInput.val();
        let url =
            window.location.protocol +
            "//" +
            window.location.host +
            "/search_suggest/?query=" +
            query +
            (commonAncestorID ? `&common_ancestor_id=${commonAncestorID}` : "");

        return $.getJSON(url, function (results) {
            if (currentSearchId !== latestSearchId) {
                return; // Ignore results from old requests
            }

            if (results.count) {
                populateResults(results);
            } else {
                emptyResults();
            }
        });
    }

    // Take user input and pass query to search function
    function userInput() {
        $searchInput.on("keyup", function () {
            if (this.value.length > 1) {
                search(this.value);
            } else {
                hideResults();
            }
        });
    }

    // Hide suggestions when clicking away
    function outOfBounds(element) {
        if (
            !$resultsContainer.is(element.target) &&
            $resultsContainer.has(element.target).length === 0
        ) {
            hideResults();
        }
    }

    function bindEvents() {
        $(window).on("load", () => userInput());

        // Hide suggestions when clicking away
        $(document).on("mouseup", (e) => outOfBounds(e));
    }

    bindEvents();
}

export default searchSuggestions;
