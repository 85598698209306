import Plyr from 'plyr';
import $ from 'jquery';
import "rangeslider.js";

export default function VideoPlayer() {
    $('input[type="range"]').rangeslider();
    $('.video-player').each((i, element) => {
        const player = new Plyr(element); 
    });
}
