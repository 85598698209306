import Cookies from 'js-cookie';

class MessageBarWarning {

    constructor( ) {
        this.dismissButton =         document.querySelector('.js-message-dismiss');
        this.messageContainer =      document.querySelector('.js-message-container');
        this.cookieName =            'gnhs-message-cookie';
        this.cookieValue =           'close intranet bar';
        this.cookieDuration =        365;
        this.activeClass =           'active';
        this.inactiveClass =         'inactive';

        this.checkCookie();
        this.bindEvents();
    }

    checkCookie() {
        if(!this.messageContainer) {
            return;
        }
        // If cookie doesn't exists
        if(!Cookies.get(this.cookieName)){
            this.messageContainer.classList.add( this.activeClass );
        } else {
            this.messageContainer.classList.add( this.inactiveClass );
        }

    }

    applyCookie(event) {
        // prevent default link action
        event.preventDefault();
        // Add classes
        if (!this.messageContainer) {
            return;
        }
        this.messageContainer.classList.remove( this.activeClass );
        this.messageContainer.classList.add( this.inactiveClass );
        // Set cookie
        Cookies.set(this.cookieName, this.cookieValue, { expires: this.cookieDuration });
    }

    bindEvents() {
        if (!this.dismissButton) {
            return;
        }
        // Bind dismiss functionality
        this.dismissButton.addEventListener( 'click', event => this.applyCookie(event) );
    }

}

export default MessageBarWarning;
