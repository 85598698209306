import {
    toggleActiveClass,
    removeActiveClass,
    toggleFixedClass,
  } from "./toggle";
  
  class IntranetCharityMobileMenu {
    constructor() {
      // Main mobile
      this.$menuNav = $(".nhsuk-header__navigation"); // The nav container
      this.$dropdownMenuNode = $(".nhsuk-header__menu-toggle"); // Menu button in top right of home
      this.$toggleSecondaryMenuNode = $(".toggle-mobile-menu");
      this.$closeSecondaryMenuNode = $(".close-mobile-menu");
      this.$secondaryMenu = $(".mobile-menu-secondary");
      this.$appContainer = $("body");
      this.$lastPrimaryLink = $(
        ".main-mobile-navigation > .main-menu > .nhsuk-header__navigation-list > li:last-child > a"
      );
      this.$lastSecondaryLink = $(
        ".secondary-menu > .nhsuk-header__navigation-list > li:last-child > a"
      );
  
      // Charity mobile
      this.$charityDonateButton = $(
        ".site-header__mobile-cta > .charity-cta__link"
      ); // Donate button at end of menu
      this.$charityMenuButton = $("#toggle-menu");
  
      this.bindEvents();
      this.updateAriaHidden(); // Initial call
      window.addEventListener('resize', this.updateAriaHidden.bind(this)); // Update on resize
    }
  
    toggleSecondaryMenu() {
      toggleActiveClass(this.$secondaryMenu);
      toggleFixedClass(this.$appContainer);
      this.updateAriaHidden();
    }
  
    // GNHS-137 Hide hidden links from screen readers
    updateAriaHidden() {
      const navLinks = document.querySelectorAll('.nhsuk-header__mobile-navigation-links');
      navLinks.forEach(navLink => {
      const isOverflowing = navLink.scrollHeight > navLink.clientHeight || navLink.scrollWidth > navLink.clientWidth;
      navLink.setAttribute('aria-hidden', isOverflowing ? 'true' : 'false');
      })
  }
  
    bindEvents() {
      this.$dropdownMenuNode.on("click", (e) => {
        e.preventDefault();
        removeActiveClass(this.$secondaryMenu);
      });
  
      this.$toggleSecondaryMenuNode.on("click", (e) => {
        e.preventDefault();
        this.toggleSecondaryMenu();
        $(".close-mobile-menu > button").trigger("focus");
      });
  
      this.$closeSecondaryMenuNode.on("click", (e) => {
        e.preventDefault();
        this.toggleSecondaryMenu();
        $(".toggle-mobile-menu > a").trigger("focus");
      });
  
      this.$lastPrimaryLink.on("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          this.toggleSecondaryMenu();
          $(".close-mobile-menu > button").trigger("focus");
        }
      });
  
      this.$lastSecondaryLink.on("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          removeActiveClass(this.$dropdownMenuNode);
          this.$menuNav.removeClass("js-show");
          this.$dropdownMenuNode.trigger("focus");
        }
      });
  
      this.$closeSecondaryMenuNode.on("keydown", (e) => {
        if (e.key === "Tab" && e.shiftKey) {
          e.preventDefault();
          this.toggleSecondaryMenu();
          $(".toggle-mobile-menu > a").trigger("focus");
        }
      });
  
      this.$charityDonateButton.on("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          removeActiveClass(this.$charityMenuButton);
          $(".charity-mobile-navigation").removeClass("js-show");
          this.$charityMenuButton.trigger("focus");
        }
      });
    }
  }
  
  export default IntranetCharityMobileMenu;