
import { toggleActiveClass, toggleFixedClass } from './toggle';

function StyleguideMenu () {
    let $menuNode = $('.js-sg-menu');
    let $menuContainer = $('.sg-acc-panel');

    $menuNode.on('click', function() {
        toggleActiveClass( $(this).children($menuContainer) );
    });
}

export default StyleguideMenu;
