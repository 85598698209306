import Isotope from "isotope-layout";
import LoadMore from "./load-more";
import DetectIE from "./detect-ie";

class Results {
    constructor(node) {
        // Classnames for isotope
        const containerClass = ".js-filterable";
        const itemClass = ".js-filterable__item";
        const itemStampClass = ".js-filterable__item--stamp";

        // Bind nodes to class to allow for use elsewhere
        this.$node = $(node);
        // Find the filtered output container
        this.$container = this.$node.find(containerClass);
        // Find the node for displaying the amount of filtered items
        this.$filterCount = this.$node.find(".filter-count");
        // Find our button for grid layout
        this.$gridButton = this.$node.find(".js-organise-grid");
        // Find our button for list layout
        this.$listButton = this.$node.find(".js-organise-list");

        // Toggle classes
        this.gridStyleClass = "listing__layout-grid";
        this.listStyleClass = "listing__layout-list";
        this.buttonActiveClass = "listing-header__item--active";

        // keep track of filters
        this.state = {
            filters: [],
        };

        // Create instance of isotope
        // For IE, set the transitionDuration to 0 because of performance issues - see ticket 225
        // For other browsers, use the default.
        if (this.$container.length) {
            this.results = new Isotope(containerClass, {
                itemSelector: itemClass,
                percentPosition: true,
                stamp: itemStampClass,
                transitionDuration: DetectIE() ? 0 : "0.4s",
                masonry: {
                    // set to the element
                    columnWidth: itemClass,
                },
            });
        }

        // Setup our event bindings
        this.bindEvents();
    }

    // Updates the filter count container with current amount of items
    updateFilterCount() {
        this.$filterCount.text(this.results.filteredItems.length);
    }

    // Manages filtering
    filterResults(filterObj) {
        // populated further down for isotope
        let filterCriteria = "";
        let searchText = "";

        // Check if we have already have a filter
        if (this.state.filters.length > 0) {
            // Loop through filters and...
            this.state.filters.forEach((item, index) => {
                // See if it is the same as an existing filter type
                if (item.type === filterObj.type) {
                    // If it is, remove the current filter from our filter array
                    this.state.filters.splice(index, 1);
                }
            });
        }

        // Add the new filter to the array
        this.state.filters.push(filterObj);

        // Populate the filter and search paramaters based off what we have stored in filters
        this.state.filters.forEach((item, index) => {
            // If the `type` is not 'search' assume it is a tag
            if (item.type != "search") {
                filterCriteria += item.value;
            } else {
                // Otherwise we assume it is a free text search value
                // Convert value to regex for use in isotopes filter callback function
                searchText = new RegExp(item.value, "gi");
            }
        });

        // Re-arrange output based on our generated filter criteria
        this.results.arrange({
            filter: function (element) {
                let $item = $(element);
                // Evaluate item against classes passed in filter criteria
                let $filtered = filterCriteria
                    ? $item.is(filterCriteria)
                    : true;
                // Evaluate item against searchText
                let $searched = searchText
                    ? $item.text().match(searchText)
                    : true;
                // If item meets both criterion then return it
                return $filtered && $searched;
            },
        });
    }

    onListButtonClick(event) {
        event.preventDefault();

        // add/remove classes on filter container
        this.$container.removeClass(this.gridStyleClass);
        this.$container.addClass(this.listStyleClass);

        // add/remove active classes on buttons
        this.$gridButton.removeClass(this.buttonActiveClass);
        this.$listButton.addClass(this.buttonActiveClass);

        // Call layout on the isotope instance
        this.results.layout();
    }

    onGridButtonClick(event) {
        event.preventDefault();

        // add/remove classes on filter container
        this.$container.removeClass(this.listStyleClass);
        this.$container.addClass(this.gridStyleClass);

        // add/remove active classes on buttons
        this.$listButton.removeClass(this.buttonActiveClass);
        this.$gridButton.addClass(this.buttonActiveClass);

        // Call layout on the isotope instance
        this.results.layout();
    }

    bindEvents() {
        // When window see the custom event fired
        $(window).on("results:filter", (e, data) => {
            // Object assign IE fallback
            if (typeof Object.assign != "function") {
                Object.assign = function (target) {
                    "use strict";
                    if (target == null) {
                        throw new TypeError(
                            "Cannot convert undefined or null to object"
                        );
                    }

                    target = Object(target);
                    for (var index = 1; index < arguments.length; index++) {
                        var source = arguments[index];
                        if (source != null) {
                            for (var key in source) {
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        source,
                                        key
                                    )
                                ) {
                                    target[key] = source[key];
                                }
                            }
                        }
                    }
                    return target;
                };
            }

            // Create a filter object with the variables
            let filterObj = {
                type: data.filterType,
                value: data.filterOn,
            };
            if (data.useAjax && data.form) {
                this.filterResultsWithAjax(
                    Object.assign({}, filterObj, {
                        ajaxForm: data.form,
                    })
                );
            } else {
                // Filter our results with the filter object
                this.filterResults(filterObj);
            }
        });

        // When the container completes updating the display
        // This is an event fired by isotope
        this.$container.on("arrangeComplete", (event, filteredItems) => {
            this.updateFilterCount(this.results.filteredItems.length);
        });

        // Bind grid button press
        this.$gridButton.on("click", (e) => this.onGridButtonClick(e));

        // Bind list button press
        this.$listButton.on("click", (e) => this.onListButtonClick(e));
    }

    filterResultsWithAjax(filterObj) {
        const ajaxSearchUrl = this.getAjaxSearchUrl(filterObj);
        const that = this;
        $(".inline-search__input").prop("disabled", true);
        $(".js-search-url").attr("href", ajaxSearchUrl);

        $.ajax(ajaxSearchUrl, {
            cache: false,
            success: function (data, status, xhr) {
                that.handleAjaxResponse(data, filterObj, xhr);
            },
        }).always(() => {
            $(".inline-search__input").prop("disabled", false);
        });
    }

    getAjaxSearchUrl(filterObj) {
        const baseUrl = filterObj.ajaxForm.attr("action");
        const serializedForm = $(filterObj.ajaxForm)
            .find(':input[value!=""]')
            .serialize();

        return serializedForm ? `${baseUrl}?${serializedForm}` : baseUrl;
    }

    getAjaxSearchQuery(filterObj) {
        return $(filterObj.ajaxForm).find(':input[name="query"]').val();
    }

    handleAjaxResponse(data, filterObj, xhr) {
        $(".inline-search__input").prop("disabled", false);
        const $listingContainer = $(".listing__container");

        $listingContainer.html(data);

        if (xhr.getResponseHeader("X-Has-Next-Page") === "true") {
            $(".show-more").show();
        } else {
            $(".show-more").hide();
        }

        let newPageTitle = document.title;

        const searchQuery = this.getAjaxSearchQuery(filterObj);

        history.pushState({}, newPageTitle, this.getAjaxSearchUrl(filterObj));
        document.title = newPageTitle;

        LoadMore();
    }
}

export default Results;
