
import { toggleActiveClass } from './toggle';

class ToggleFilters {

    constructor( ) {
        this.$menuNode = $('.js-toggle');
        this.$menuContainer = $('.js-toggle-item');

        this.bindEvents();
    }

    toggleMenu(event) {
        // prevent default link action
        event.preventDefault();

        toggleActiveClass( this.$menuContainer );
    }

    bindEvents() {
        this.$menuNode.on('click', (e) => this.toggleMenu(e) );
    }

}

export default ToggleFilters;
