

class Filter {

    constructor( node ) {

        // Bind node to class
        this.$node = $( node );
        // Get type
        this.filterType = this.$node.data( 'filter-type' );
        // Find options
        this.$options = this.$node.find('.filters__option');
        // Find header
        this.$header = this.$node.find('.filters__heading');
        // Find the form
        this.$form = $(this.$node.parents('form:first'));

        this.bindEvents();

    }

    updateCurrentText(title) {
        // Update header text
        this.$header.text( title );
    }

    updateInput(inputName, inputValue) {
        this.$form.find(`input[name=${inputName}]`).val(inputValue);
    }

    onClick( event ) {
        // Get data from selected filter
        let filterValue = event.target.getAttribute('data-filter');
        let filterText = event.target.textContent.trim();

        if (filterValue !== null) {
            if (this.$form) {
                this.updateInput(
                    $(event.target).data('inputName'),
                    $(event.target).data('inputValue')
                );
            }

            // Fire an event on window with a custom filter object for use elsewhere
            $( window ).trigger( 'results:filter', {
                filterType : this.filterType,
                filterOn : filterValue,
                form: this.$form,
                useAjax: this.useAjax(),
            });
            // Update the filter text
            this.updateCurrentText( filterText );
        }
    }

    bindFilterSelect( event ) {
        // Get our element from current target
    }

    bindEvents() {

        // Bind click event
        this.$node.on( 'click', ( e ) => this.onClick( e ) );

        // Loop through all options found in select box and bind events
        this.$options.each((i, node) => {
            // Individual element
            let $elem = $( node );
            // Click event handler
            $elem.on('click', ( event ) => {
                // Prevent default browser operation
                event.preventDefault();
                // Remove active classes from options
                this.$options.removeClass('active');
                // Apply active to select option
                $elem.toggleClass('active');
            });

        });
    }

    useAjax() {
        if (!this.$form) {
            return false;
        }

        return Boolean(this.$form.data('useAjax'));
    }
}

export default Filter;
